import { BrowserRouter } from 'react-router-dom';
import './util/i18n';
import UserContextProvider from 'contexts/UserContext';
import Spinner from './components/common/Spinners/Spinner';
import AppRoutes from './Routes';
import { useAppInitialization } from './hooks/useAppInitialization';

const App = () => {
  const { isInitialized, isLoading } = useAppInitialization();

  if (isLoading) {
    return <Spinner theme="default" animationName="spin" />;
  }

  return (
    <UserContextProvider>
      <BrowserRouter>
        <AppRoutes isInitialized={isInitialized} />
      </BrowserRouter>
    </UserContextProvider>
  );
};

export default App;
