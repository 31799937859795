// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
import * as msTeams from '@microsoft/teams-js';

export function stubMsTeams(teams: typeof msTeams) {
  teams.app.initialize = initializeStub;
  teams.app.getContext = getContextStub;
  teams.app.registerOnThemeChangeHandler = getRegisterOnThemeChangeHandlerStub;
  teams.app.openLink = openLinkStub;

  teams.authentication.getAuthToken = getAuthTokenStub;

  teams.pages.config.registerOnSaveHandler = registerOnSaveHandlerStub;
  teams.pages.config.setValidityState = setValidityStateStub;
}

const { HostName, FileOpenPreference, HostClientType, FrameContexts } = msTeams;

function initializeStub() {
  return Promise.resolve();
}

function getContextStub() {
  return Promise.resolve({
    app: {
      locale: 'en-gb',
      sessionId: 'cf28a436-7d02-4c76-bb99-a8104f65fbd7',
      theme: 'dark',
      iconPositionVertical: 331,
      parentMessageId: '',
      userClickTime: 1727943064718,
      userFileOpenPreference: FileOpenPreference.Inline,
      host: {
        name: HostName.teamsModern,
        clientType: HostClientType.web,
        sessionId: '03fde1dc-a024-4ff6-a89d-a043b87aefc8',
        ringId: 'general',
      },
      appLaunchId: 'c23030af-e6c6-46b3-bf69-e0241a3bd18f',
    },
    page: {
      id: 'a861cd45-22ce-49b6-bc64-9bccf3b4e41a',
      frameContext: FrameContexts.content,
      subPageId: '',
      isFullScreen: false,
      isMultiWindow: false,
      isBackgroundLoad: false,
      sourceOrigin: '',
    },
    user: {
      id: 'b12e4dd1-ba30-4d45-8ce4-8e09d76b5a91',
      displayName: 'Homer Simpson',
      licenseType: 'Unknown',
      loginHint: 'hsimpson@insights.com',
      userPrincipalName: 'hsimpson@insights.com',
      tenant: {
        id: '229e867c-008c-43c1-a796-140ec8a93826',
        teamsSku: 'unknown',
      },
    },
    sharePointSite: {
      teamSiteUrl: '',
      teamSiteDomain: 'ildm365.sharepoint.com',
      teamSitePath: '',
      teamSiteId: '',
    },
    dialogParameters: {},
    chat: {
      id: '19:meeting_MzQ2ZjA4MTctNzFkZC00ZWRmLTg2NjgtZjIyNTY3YTMwY2Fl@thread.v2',
    },
  } satisfies Context);
}

function getRegisterOnThemeChangeHandlerStub(handler: ThemeHandler) {
  handler('default');
}

function openLinkStub(): Promise<void> {
  return Promise.resolve();
}

function getAuthTokenStub() {
  return Promise.resolve('some-token');
}
function registerOnSaveHandlerStub() {} // no-op

function setValidityStateStub() {} // no-op

type Context = msTeams.app.Context;
type ThemeHandler = msTeams.app.themeHandler;
